import React from "react";
import { Doughnut } from "react-chartjs-2";
import "../../styling/Donut.css";

const AnalyticsDonut = () => {

  const data = {
    labels: ["Booked", "Non-booked", "Cancelled"],
    datasets: [
      {
        data: [50, 45, 5],
        backgroundColor: ["#21A62C", "#F99600", "#E02121"],
        hoverBackgroundColor: ["#260F99", "#B5A6FF", "#D9D9D9"],
      },
    ],
  };

  const labels = data.labels.map((label, i) => ({
    label,
    value: data.datasets[0].data[i],
    backgroundColor: data.datasets[0].backgroundColor[i],
  }));

  return (
    <div className="right-donut">
      <h2 style={{ paddingTop: "20px", paddingLeft: '30px', fontWeight: '500', color: '#1C1154' }}>Success Rate</h2>
      
      <div className="donut">
        <Doughnut
          style={{ width: '140px', height: '120px'}}
          data={data}
          options={{
            cutout: '80%',
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false, // Disable the default legend
              },
            },
          }}
        />
      </div>

      {/* Custom Legend */}
      <div className="custom-legend">
        {labels.map((item, index) => (
          <div key={index} className="legend-item">
            <div className="color-box" style={{ backgroundColor: item.backgroundColor }}></div>
            <div className="legend-label">{item.label}</div>
            <div className="legend-value">{item.value}%</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnalyticsDonut;
