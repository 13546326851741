import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useLocation } from "react-router-dom";



const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const { eventId, businessId } = location.state || {};

const PAYMENT_SUCCESS_URL = `http://localhost:3000/client-home?businessId=${businessId}`;
const UPDATE_PAYMENT_API_URL = "http://localhost:3001/events/update-payment-status";
  

  const updatePaymentStatus = async (eventId) => {
    console.log("update payment status! ")
    try {
      const response = await axios.post(
        UPDATE_PAYMENT_API_URL,
        { eventId, payment: "paid" },
        { withCredentials: true }
      );
      console.log("Payment status updated:", response.data);
    } catch (error) {
      console.error("Failed to update payment status:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;
  
    setIsLoading(true);
    setMessage("Payment in Progress");
  
    try {
      const resp = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });
  
      if (resp.error) {
        console.error("Stripe Error:", resp.error.message);
        setMessage("Some Error Occurred !!");
      } else if (resp.paymentIntent?.status === "succeeded") {
        console.log("Payment succeeded, updating payment status");
        await updatePaymentStatus(eventId);
  
        // Redirect manually
        window.location.href = PAYMENT_SUCCESS_URL;
      }
    } catch (error) {
      console.error("Payment submission error:", error);
      setMessage("Payment process failed!");
    }
  
    setIsLoading(false);
  };
 
    return (
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
            <form onSubmit={handleSubmit}>
                <div style={{ width: '100%', backgroundColor: '#e5e7eb', boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)', borderRadius: '0.5rem' }}>
                    <div style={{ padding: '1.5rem' }}>
                        <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', textAlign: 'center' }}>
                            Complete your payment here!
                        </h1>
                        <PaymentElement />
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1.5rem' }}>
                            <button
                                style={{
                                    padding: '0.5rem 1rem',
                                    backgroundColor: '#3b82f6',
                                    borderRadius: '0.75rem',
                                    color: '#fff',
                                    textAlign: 'center',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}
                                disabled={isLoading || !stripe || !elements}
                            >
                                {isLoading ? "Loading..." : "Pay now"}
                            </button>
                        </div>
                        {message && <div>{message}</div>}
                    </div>
                </div>
            </form>
        </div>
    );
};
 
export default PaymentForm;
