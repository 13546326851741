import React from "react";
import "../styling/BookABooth.css";

import profilePic from "../images/John-Wick.jpg.avif";

const BookABooth = () => {
  const packages = (
    <div>
      <div
        className="invoiceContent"
        style={{
          width: "90.2%",
          background: "#FFFFFF",
          height: "fitContent",
        }}
      >
        <h2
          style={{ color: "#1C1154", marginTop: "0px", marginBottom: "30px" }}
        >
          Choose a Package
        </h2>
        <div
          className="right-panel-content"
          style={{ marginTop: "0px", padding: "20px" }}
        >
          <div className="right-panel-arrow-container">
            <span>
              <h2
                style={{
                  color: "#1C1154",
                  marginTop: "0px",
                  marginBottom: "5px",
                  fontWeight: "425",
                }}
              >
                Bronze Package
              </h2>
            </span>{" "}
            <h4
              style={{
                alignSelf: "flex-end",
                marginRight: "10px",
                marginBottom: "5px",
                color: "#1C1154",
                fontWeight: "425",
                marginTop: "auto",
              }}
            >
              {" "}
              $250.00 <input style={{ paddingBottom: "3px" }} type="checkbox" />
            </h4>
          </div>

          <div style={{ color: "#4D4D4D" }}>
            <p style={{ marginBottom: "3px" }}>3 Hours On-Site Attendant</p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Unlimited Prints
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Online Downloads
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Party Props Custom
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Photostrips Design
            </p>
          </div>
        </div>
        <div
          className="right-panel-content"
          style={{ marginTop: "20px", padding: "20px" }}
        >
          <div className="right-panel-arrow-container">
            <span>
              <h2
                style={{
                  color: "#1C1154",
                  marginTop: "0px",
                  marginBottom: "5px",
                  fontWeight: "425",
                }}
              >
                Silver Package
              </h2>
            </span>{" "}
            <h4
              style={{
                alignSelf: "flex-end",
                marginRight: "10px",
                marginBottom: "5px",
                color: "#1C1154",
                fontWeight: "425",
                marginTop: "auto",
              }}
            >
              {" "}
              $250.00 <input style={{ paddingBottom: "3px" }} type="checkbox" />
            </h4>
          </div>

          <div style={{ color: "#4D4D4D" }}>
            <p style={{ marginBottom: "3px" }}>3 Hours On-Site Attendant</p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Unlimited Prints
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Online Downloads
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Party Props Custom
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Photostrips Design
            </p>
          </div>
        </div>
        <div
          className="right-panel-content"
          style={{ marginTop: "20px", padding: "20px" }}
        >
          <div className="right-panel-arrow-container">
            <span>
              <h2
                style={{
                  color: "#1C1154",
                  marginTop: "0px",
                  marginBottom: "5px",
                  fontWeight: "425",
                }}
              >
                Gold Package
              </h2>
            </span>{" "}
            <h4
              style={{
                alignSelf: "flex-end",
                marginRight: "10px",
                marginBottom: "5px",
                color: "#1C1154",
                fontWeight: "425",
                marginTop: "auto",
              }}
            >
              {" "}
              $250.00 <input style={{ paddingBottom: "3px" }} type="checkbox" />
            </h4>
          </div>

          <div style={{ color: "#4D4D4D" }}>
            <p style={{ marginBottom: "3px" }}>3 Hours On-Site Attendant</p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Unlimited Prints
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Online Downloads
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Party Props Custom
            </p>
            <p style={{ marginBottom: "3px", marginTop: "3px" }}>
              Photostrips Design
            </p>
          </div>
        </div>
        <h2 style={{ marginTop: "30px", color: "#1C1154" }}>Add Ons</h2>
        <div className="bordered-box" style={{ marginBottom: "10px" }}>
          <img src={profilePic} alt="Your Image" className="image" />
          <div className="content">
            <div style={{ color: "#1C1154" }}> Custom Party Props </div>
            <p
              style={{
                color: "#4D4D4D",
                marginTop: "7px",
                marginBottom: "5px",
              }}
            >
              Let us know what theme and colors{" "}
            </p>
            <p style={{ color: "#4D4D4D", marginTop: "0px" }}>
              {" "}
              you want and we’ll make it happen!
            </p>
          </div>
          <div>
            <p
              className="rightmost-content"
              style={{ marginTop: "-20px", color: "#1C1154" }}
            >
              $150.00 <input style={{ paddingBottom: "3px" }} type="checkbox" />
            </p>
          </div>
        </div>
        <div className="bordered-box" style={{ marginBottom: "10px" }}>
          <img src={profilePic} alt="Your Image" className="image" />
          <div className="content">
            <div style={{ color: "#1C1154" }}> Custom Party Props </div>
            <p
              style={{
                color: "#4D4D4D",
                marginTop: "7px",
                marginBottom: "5px",
              }}
            >
              Let us know what theme and colors{" "}
            </p>
            <p style={{ color: "#4D4D4D", marginTop: "0px" }}>
              {" "}
              you want and we’ll make it happen!
            </p>
          </div>
          <div>
            <p
              className="rightmost-content"
              style={{ marginTop: "-20px", color: "#1C1154" }}
            >
              $150.00 <input style={{ paddingBottom: "3px" }} type="checkbox" />
            </p>
          </div>
        </div>
        <div className="bordered-box">
          <img src={profilePic} alt="Your Image" className="image" />
          <div className="content">
            <div style={{ color: "#1C1154" }}> Custom Party Props </div>
            <p
              style={{
                color: "#4D4D4D",
                marginTop: "7px",
                marginBottom: "5px",
              }}
            >
              Let us know what theme and colors{" "}
            </p>
            <p style={{ color: "#4D4D4D", marginTop: "0px" }}>
              {" "}
              you want and we’ll make it happen!
            </p>
          </div>
          <div>
            <p
              className="rightmost-content"
              style={{ marginTop: "-20px", color: "#1C1154" }}
            >
              $150.00 <input style={{ paddingBottom: "3px" }} type="checkbox" />
            </p>
          </div>
        </div>
        <div
          style={{
            height: "60px",
            backgroundColor: "#FFFFFF",
            marginBottom: "20px",
            paddingTop: "5%",
            width: "100%",
          }}
        >
          <button
            style={{
              marginTop: "35px",
              height: "40px",
              width: "100%",
              background: "#260F99",
              border: "1px solid #43425D ",
              color: "#FFFFFF",
              fontSize: "17px",
              fontWeight: "450",
            }}
          >
            Update Book-A-Booth Link
          </button>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#ffffff",
          width: "83.6%",
          border: "1px solid #D9D9D9",
          marginTop: "35px",
          padding: "8%",
        }}
      >
        <h2 style={{ color: "#1C1154", fontWeight: "450", marginTop: "0px" }}>
          {" "}
          Schedule Your Event
        </h2>
        <h4 style={{ color: "#1C1154", marginTop: "35px" }}>
          {" "}
          Name of your event
        </h4>

        <input
          placeholder="  Name"
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
          }}
        ></input>
        <h4 style={{ color: "#1C1154", marginTop: "35px" }}>
          {" "}
          What is your event?
        </h4>

        <input
          placeholder="  Wedding"
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
          }}
        ></input>

        <h4 style={{ color: "#1C1154", marginTop: "35px" }}> Choose a Date</h4>

        <input
          placeholder="  Wedding"
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
          }}
        ></input>
        <h4 style={{ color: "#1C1154", marginTop: "35px" }}>
          {" "}
          What time is your event?
        </h4>

        <input
          placeholder="  Wedding"
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
          }}
        ></input>
        <h4 style={{ color: "#1C1154", marginTop: "35px" }}>
          {" "}
          Where is your event located
        </h4>

        <input
          placeholder="  Wedding"
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "50px",
          }}
        ></input>
        <h4 style={{ color: "#1C1154", marginTop: "35px" }}>
          {" "}
          Tell us about your event!
        </h4>

        <input
          style={{
            border: "hidden",
            border: "1px solid #D9D9D9",
            fontSize: "15px",
            width: "100%",
            height: "120px",
          }}
        ></input>
        <div
          style={{
            height: "80px",
            backgroundColor: "#FFFFFF",
            marginBottom: "20px",
            paddingTop: "5%",
            width: "100%",
          }}
        >
          <button
            style={{
              marginTop: "35px",
              height: "40px",
              width: "100%",
              background: "#260F99",
              border: "1px solid #43425D ",
              color: "#FFFFFF",
              fontSize: "17px",
              fontWeight: "450",
            }}
          >
            Add another date for this booking
          </button>
        </div>
      </div>
    </div>
  );

  const boothRightPanel = (
    <div
      className="invoiceContent"
      style={{
        width: "83%",
        background: "#FFFFFF",
        marginTop: "30px",
        height: "fitContent",
        borderLeft: "hidden",
      }}
    >
      <div
        className="right-panel-content"
        style={{ marginTop: "0px", padding: "20px" }}
      >
        <div className="right-panel-arrow-container">
          <span>
            <h4
              style={{
                color: "#1C1154",
                marginTop: "0px",
                marginBottom: "5px",
                fontWeight: "425",
              }}
            >
              Bronze Package
            </h4>
          </span>{" "}
          <h4
            style={{
              alignSelf: "flex-end",
              marginRight: "10px",
              marginBottom: "5px",
              color: "#1C1154",
              fontWeight: "425",
              marginTop: "auto",
            }}
          >
            {" "}
            $250.00
          </h4>
        </div>
      </div>
      <div
        className="right-panel-content"
        style={{ marginTop: "20px", padding: "20px" }}
      >
        <div className="right-panel-arrow-container">
          <span>
            <h4
              style={{
                color: "#1C1154",
                marginTop: "0px",
                marginBottom: "5px",
                fontWeight: "425",
              }}
            >
              Custom Party Props
            </h4>
          </span>{" "}
          <h4
            style={{
              alignSelf: "flex-end",
              marginRight: "10px",
              marginBottom: "5px",
              color: "#1C1154",
              fontWeight: "425",
              marginTop: "auto",
            }}
          >
            {" "}
            $50.00
          </h4>
        </div>
      </div>
      <div
        className="right-panel-content"
        style={{ marginTop: "20px", padding: "10px" }}
      >
        <div className="right-panel-arrow-container">
          <p
            style={{
              color: "#625C73",
              marginTop: "0px",
              marginBottom: "5px",
              fontWeight: "425",
            }}
          >
            MM/DD/YY
          </p>
        </div>
      </div>
      <div
        className="right-panel-content"
        style={{ marginTop: "20px", padding: "10px" }}
      >
        <div className="right-panel-arrow-container">
          <p
            style={{
              color: "#625C73",
              marginTop: "0px",
              marginBottom: "5px",
              fontWeight: "425",
            }}
          >
            00:00 PM - 04:00 PM
          </p>
        </div>
      </div>

      <div
        className="right-panel-content"
        style={{ marginTop: "20px", padding: "10px" }}
      >
        <div className="right-panel-arrow-container">
          <p
            style={{
              color: "#625C73",
              marginTop: "0px",
              marginBottom: "5px",
              fontWeight: "425",
            }}
          >
            1234 Kumquat St. Riverside, CA 92503
          </p>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", color: "#4D4D4D" }}
      >
        <p>You won’t be charged yet</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "#4D4D4D",
        }}
      >
        <div> Sliver Package</div>
        <div> $500</div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "#4D4D4D",
          marginTop: "10px",
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <div> Custom Party Props</div>
        <div style={{ marginBottom: "5px" }}> $50</div>
      </div>

      <div
        style={{
          height: "80px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          paddingTop: "5%",
          width: "100%",
        }}
      >
        <button
          style={{
            marginTop: "35px",
            height: "40px",
            width: "100%",
            background: "#260F99",
            border: "1px solid #43425D ",
            color: "#FFFFFF",
            fontSize: "17px",
            fontWeight: "450",
          }}
        >
          Book Your Booth
        </button>
      </div>
    </div>
  );

  return (
    <div className="bookAbooth">
      <div
        style={{
          border: "1px solid #D9D9D9",
          background: "#F2F2F2",
          width: "44%",
          marginLeft: "12%",
        }}
      >
        {" "}
        {packages}
      </div>
      <div
        style={{
          border: "1px solid #D9D9D9",
          background: "#FFFFFF",
          width: "25%",
          marginRight: "12%",
          height: "696px",
        }}
      >
        {" "}
        {boothRightPanel}
      </div>
    </div>
  );
};

export default BookABooth;
