import "../../styling/Header.css";
import logo from "../../images/BloomBoothLogo.png";
import profilePic from "../../images/profilePic.jpg";
import { IoNotifications } from "react-icons/io5";

const BookingHeader = ({ onClickAccountInfo, brandLogo }) => {
  console.log(brandLogo);
  return (
    <div>
      
      <header className="header" style={{ height: "50px" , background: '#ffffff' }}>
        <div style={{ display: "flex", width: "214%", marginTop: "-11px" }}>
          <img style={{ marginLeft: "1%", width: "15%" }} src = 'https://cdn.prod.website-files.com/65997e123627c8e86f726abe/65a5db5cbf3e4af51b463bd7_Boothbloom-logo-color.svg' alt="" />
        </div>
      </header>
    </div>
  );
};

export default BookingHeader;
