import React, { useState, useEffect } from "react";
import ClientLandingPage from "./ClientLandingPage";
import ClientPortal from "./ClientPortal";
import LeftClientPanel from "../panels/LeftClientPanel";
import axios from "axios";
import ClientHeader from "./ClientHeader";
import "../../styling/App.css";
import ClientPortalBusiness from "./ClientPortalBusiness";

const BusinessClientEvent = () => {
  const [client, setClient] = useState({});
  const [brandingColours, setBrandingColours] = useState();
  const [selectedTab, setSelectedTab] = useState("landing"); // Flag to control which set of tabs is shown
  const [events, setEvents] = useState([]);
  const [brandLogo, setBrandLogo] = useState(null);

  // Fetch client data and branding on component mount
  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get("http://localhost:3001/client-user", {
          withCredentials: true,
        });
        setClient(response.data.data);
      } catch (error) {
        console.log("Error fetching client data: ", error);
      }
    };

  

    const fetchEventData = async (businessId) => {
      try {
        const response = await axios.post(
          "http://localhost:3001/client/events",
          {
            businessId,
          },
          { withCredentials: true }
        );

        setEvents(response.data.data);
      } catch (error) {
        console.log("Error fetching branding data: ", error);
      }
    };

    fetchClientData().then(() => {
      if (client.businessId) {
        fetchEventData(client.businessId);
      }
    });
  }, [client.businessId]);

  // Handle tab switch between landing page and portal
  const handleTabSwitch = (tab) => {
    setSelectedTab(tab);
  };

  const handleBacktoAccount = () => {
    setSelectedTab("landing");
  };

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <div style={{ display: "flex" }}>
        <div className="clientProfileDetails" style={{width: "18.4%", marginLeft: "0%" }}>
          {/* Left Panel stays constant */}
          <LeftClientPanel
            handleBackToAccount={handleBacktoAccount}
            clientData={client}
            brandingColours={brandingColours}
            selectedTab={selectedTab}
          />
        </div>
        <div className="clientLandingTabs" style={{ paddingLeft: "10px" }}>
          <ClientPortalBusiness brandingColours={brandingColours} />
        </div>
      </div>
    </div>
  );
};

export default BusinessClientEvent;
