import React from "react";

import "../../styling/Dashboard.css";
import profilePic from "../../images/happy-birthday-background-sign-tropical-beach-hawaii-63372910.jpg.webp";
import profilePicture from "../../images/John-Wick.jpg.avif";

const LeftClientPanel = ({
  handleBackToAccount,
  clientData,
  brandingColours,
  selectedTab,
}) => {
  const primaryColor = "black";

  const buttonStyle = { display: 'inline-block',
    padding: '10px, 20px',
    background: brandingColours?.primaryColor, 
    color: brandingColours?.secondaryColor,
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '75%',
    height: '35px',
    fontSize: '15px',
  };

  const clientInfo = (
    <div>
      <div>
        <img
          src={profilePic}
          alt="cover"
          style={{ width: "100%", height: "150px" }}
        />
        <img
          src={profilePicture}
          alt="Profile"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            overflow: "hidden",
            marginTop: "-28px",
            marginLeft: "25px",
          }}
        />
      </div>
      <div
        style={{ marginLeft: "25px", fontWeight: "500", color: primaryColor }}
      >
        <h2>{clientData && clientData.name ? clientData.name : ""}</h2>
        <h4 style={{ marginBottom: "-5px" }}>Phone</h4>
        <p style={{ color: "#4D4D4D" }}>
          {clientData && clientData.name ? clientData.phone : ""}
        </p>
        <h4 style={{ marginBottom: "-5px" }}>Email</h4>
        <p style={{ color: "#4D4D4D" }}>
          {clientData && clientData.name ? clientData.email : ""}
        </p>
        <h4 style={{ marginBottom: "-5px" }}>Most Recent Booking</h4>
        <p style={{ color: "#4D4D4D" }}>Mar 21, 2024</p>
        <h4 style={{ marginBottom: "-5px" }}>Total Events Booked</h4>
        <p style={{ color: "#4D4D4D" }}>5</p>
      </div>
      {selectedTab !== "landing" && (
        <div style={{ marginLeft: "25px", paddingBottom: '10px' }}>
          <button style={buttonStyle} onClick={handleBackToAccount} className="events-button-hover-underline">
            {" "}
            View All Events{" "}
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div>
      <div>{clientInfo}</div>
    </div>
  );
};

export default LeftClientPanel;
