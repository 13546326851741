// Content.js
import React from "react";
import "../styling/Content.css";
import AccountInfo from "./AccountInfo";
import Analytics from "./Analytics";
import Bookings from "./Bookings";
import BoothInfo from "./BoothInfo";
import BusinessClientView from "./BusinessClientView";
import BusinessClientEvent from "./clientPortal/BusinessClientEvent";
import Calendar from "./Calender";
import Clients from "./Clients";
import CompanyInfo from "./CompanyInfo";
import Dashboard from "./Dashboard";

const Content = ({ selectedMenuItem, handleClientClick, onEventClick }) => {
  let contentComponent;
  console.log(selectedMenuItem);
  switch (selectedMenuItem) {
    case "Dashboard":
      contentComponent = <Dashboard />;
      break;
    case "Bookings":
      contentComponent = <Bookings onEventClick={onEventClick} />;
      break;
    case "Clients":
      contentComponent = <Clients handleClientClick={handleClientClick} />;
      break;
    case "Analytics":
      contentComponent = <Analytics />;
      break;
    case "Calendar":
      contentComponent = <Calendar />;
      break;
    case "account":
      contentComponent = <AccountInfo />;
      break;
    case "company":
      contentComponent = <CompanyInfo />;
      break;
    case "booth":
      contentComponent = <BoothInfo />;
      break;
    case "businessEvent":
      contentComponent = <BusinessClientEvent /> ;
      break;
    default:
      contentComponent = <Dashboard />;
  }
  return <div className="content">{contentComponent}</div>;
};

export default Content;
