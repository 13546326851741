import React, { useState } from "react";
import Table from "rc-table";
import "../../styling/Dashboard.css";
import { PiCaretUpDownFill } from "react-icons/pi";

const ClientsTable = ({ handleClientClick }) => {
    const [sortOrder, setSortOrder, selectedRows, setSelectedRows] = useState({});

    const onClientClick = (record) => {
        handleClientClick(record)
    };

    const columns = [
        {
            title: "",
            dataIndex: "id",
            key: "id",
            render: () => <input type="checkbox" />,
            width: 9,
            align: "center",
        },
        {
            title: (
                <div className="custom-header" onClick={() => handleSort("client")}>
                    <PiCaretUpDownFill className="table-header-icon" />
                    Name
                </div>
            ),
            dataIndex: "name",
            key: "name",
            width: 100,
            align: "left",
            render: (text, record) => (
                <a
                href={`#${record.key}`} onClick={() => onClientClick(record)}
                    style={{ color: "#1C1154", textDecoration: "none" }}
                >
                    <h4 style={{ margin: "0px" }}> {text} </h4>
                </a>
            ),
        },
        {
            title: (
                <div className="custom-header" onClick={() => handleSort("client")}>
                    <PiCaretUpDownFill className="table-header-icon" />
                    Email
                </div>
            ),
            dataIndex: "email",
            key: "email",
            width: 100,
            align: "left",
        },
        {
            title: (
                <div className="custom-header" onClick={() => handleSort("status")}>
                    <PiCaretUpDownFill className="table-header-icon" />
                    Status
                </div>
            ),
            dataIndex: "status",
            key: "status",
            width: 100,
            align: "left",
        },
    ];

    const data = [
        {
            name: "John Wick",
            email: "johndoe@gmail.com",
            status: "Event Complete",
            key: "1",
        },
        {
            name: "Jack Sparrow",
            email: "johndoe@gmail.com",
            status: "Event Complete",
            key: "1",
        },
        {
            name: "Thomas Edison",
            email: "johndoe@gmail.com",
            status: "Event Complete",
            key: "1",
        },
        {
            name: "Vladimir Putin",
            email: "johndoe@gmail.com",
            status: "Event Complete",
            key: "1",
        },
        {
            name: "Narendra Modi",
            email: "johndoe@gmail.com",
            status: "Event Complete",
            key: "1",
        },
        {
            name: "Robert Di Nero",
            email: "johndoe@gmail.com",
            status: "Event Complete",
            key: "1",
        },
        {
            name: "Tom Holland",
            email: "johndoe@gmail.com",
            status: "Event Complete",
            key: "1",
        },
        {
            name: "Lionel Messi",
            email: "johndoe@gmail.com",
            status: "Event Complete",
            key: "1",
        },
        {
            name: "Kevin Debruyne",
            email: "johndoe@gmail.com",
            status: "Event Complete",
            key: "1",
        },
        
    ];

    const customFooter = () => (
        
          
            
        <div style={{ marginLeft: '30px', display: 'flex', alignItems: 'baseline'}}>
        {/* <input type="checkbox" onChange={(e) => handleSelectAll(e.target.checked)} /> */}
        <span style={{ margin: '0 4px' }} />

  {/* Download Button */}
  <button className="tableDownloadButton" onClick={() => handleDownload()}>Delete</button>
        </div>
    );

    const handleSort = (column) => {
        setSortOrder((prevSortOrder) => ({
            ...prevSortOrder,
            [column]: prevSortOrder[column] === "asc" ? "desc" : "asc",
        }));
        // Add your sorting logic here
    };

    // const handleSelectAll = (checked) => {
    //     const allRows = data.map((item) => item.key); // Assuming you have a unique key for each row
    //     setSelectedRows(checked ? allRows : []);
    // };

    const handleDelete = () => {
        // Implement delete logic using selectedRows
        console.log("Deleting rows:", selectedRows);
    };

    const handleDownload = () => {
        // Implement download logic using selectedRows
        console.log("Downloading rows:", selectedRows);
    };

    const rowClassName = () => "custom-table-row";

    //   <tfoot>
    //   <tr>
    //     <td colSpan={columns.length}>
    //       {/* Checkbox */}
    //       <input type="checkbox" id="selectAll" />
    //       <label htmlFor="selectAll">Select All</label>

    //       {/* Gap */}
    //       <span style={{ margin: '0 4px' }} />

    //       {/* Download Button */}
    //       <button onClick={() => handleDownload()}>Download</button>
    //     </td>
    //   </tr>
    // </tfoot>

      const customRowClassName = (record, index) => {
        // Add a class to every row for the bottom border
        return 'table-row-with-border';
      };

    return (
        <div>
        <div className="events-table">
            <Table
                columns={columns}
                data={data}
                scroll={{ x: "100%" }}
                rowClassName={(record, index) => `${rowClassName(record, index)} ${customRowClassName(record, index)}`}
                components={{
                    header: {
                        cell: (props) => (
                            <th
                                {...props}
                                style={{
                                    borderBottom: "1px solid #D9D9D9",
                                    height: "50px",
                                    textAlign: "left",
                                }}
                            >
                                {props.children}
                            </th>
                        ),
                    },
                }}
                footer={customFooter}
            />
        </div>
        </div>
    );
};

export default ClientsTable;
