import React from "react";
import "../../styling/Dashboard.css";

const PackageRightPanel = () => {
  return (
    <div
      className="right-panel"
      style={{ marginTop: "107px", width: "22%", height: "auto" }}
    >
      <div>
        <div className="right-panel-content"
          
            style={{
              color: "#000000",
              marginTop: "2px",
              marginBottom: "15px",
            }}
          >
            01
          <div style={{ marginBottom: '15px', marginTop: '5px' }}>
              Proposal
            
          </div>
        </div>

        <div className="right-panel-content"
          
          style={{
            color: "#000000",
            marginTop: "0px",
            marginBottom: "15px",
          }}
        >
          02
        <div style={{ marginBottom: '15px', marginTop: '5px' }}>
            Payment Schedule
          
        </div>
      </div>
          
        

      </div>
    </div>
  );
};

export default PackageRightPanel;
