import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ClientEmailVerify = () => {
  const navigate = useNavigate();
  const token = new URLSearchParams(window.location.search).get('token');

  useEffect(() => {
    console.log("calling.... ")
    const clientEmailVerify = async () => {
      try {
        await axios.post('/api/verify-email', { token });
        alert('Email verified successfully!');
        navigate('/client-login');
      } catch (error) {
        console.error('Verification failed:', error);
        alert('Failed to verify email.');
      }
    };

    if (token) {
        clientEmailVerify();
    }
  }, [token, navigate]);

  return <div>Verifying your email...</div>;
};

export default ClientEmailVerify;