import React, { useState, useRef, useEffect } from "react";
import {
  MdAdd,
  MdCheckBox,
  MdTextFields,
  MdEdit,
  MdDelete,
} from "react-icons/md";
import FileUploadComponent from "../fileUpload/FileUploadComponent";
import { FaRegCheckCircle } from "react-icons/fa";
import { BsSend } from "react-icons/bs";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosAddCircleOutline,
} from "react-icons/io";
import "../../styling/QuestionnaireTabBusinessCss.css";
import profilePic from "../../images/happy-birthday-background-sign-tropical-beach-hawaii-63372910.jpg.webp";
import DeleteQuestionModal from "../modals/DeleteQuestionModal";

const QuestionnaireTabBusiness = ({ brandingColours }) => {
  const [questions, setQuestions] = useState([]);
  const [showQuestionOptions, setShowQuestionOptions] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalButton, setModalButton] = useState(null);
  const [questionToDelete, setQuestionToDelete] = useState(null); // Store the question index to delete

  const optionsBoxRef = useRef(null);
  const addButtonRef = useRef(null);

  // Toggle visibility of question options box
  const handleAddQuestionClick = () =>
    setShowQuestionOptions(!showQuestionOptions);

  // Hide options when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        optionsBoxRef.current &&
        !optionsBoxRef.current.contains(event.target)
      ) {
        setShowQuestionOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleAddQuestion = (type) => {
    const newQuestion = { type, text: "", options: [], extraField: false };
    setQuestions([...questions, newQuestion]);
    setShowQuestionOptions(false);
  };

  

  const openModal = (index) => {
    setQuestionToDelete(index); // Set the question index to delete
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setQuestionToDelete(null); // Reset question index when modal is closed
  };

  const handleDeleteQuestion = () => {
    if (isEdit && questionToDelete !== null) {
      const updatedQuestions = questions.filter((_, i) => i !== questionToDelete);
      setQuestions(updatedQuestions);
    }
    closeModal();
  };

  const handleInputChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].text = value;
    setQuestions(updatedQuestions);
  };

  

  const handleAddOption = (index) => {
    if (isEdit) {
      const updatedQuestions = [...questions];
      updatedQuestions[index].options.push("");
      setQuestions(updatedQuestions);
    }
  };

  const handleEditSave = () => {
    setIsEdit(!isEdit);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  const handleArrowClick = (index) => {
    if (isEdit) {
      const updatedQuestions = [...questions];
      updatedQuestions[index].extraField = true;
      setQuestions(updatedQuestions);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <DeleteQuestionModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        deleteQuestion={handleDeleteQuestion}
      />
      <img
        src={profilePic}
        alt="cover"
        style={{ width: "100%", height: "224px" }}
      />
      <h2 style={{ marginTop: "30px", color: "#000000", marginBottom: "30px" }}>
        Event Info
      </h2>

      {/* Render added questions */}
      {questions.map((question, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
            borderBottom: "1px solid #D9D9D9",
            paddingBottom: "15px",
            position: "relative",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="text"
              className="custom-input"
              style={{
                flex: 1,
                border: "none",
                borderBottom: "1px solid #D9D9D9",
                color: "#080700",
                fontSize: "16px",
                padding: "5px",
              }}
              placeholder={
                question.type === "text"
                  ? "Enter text question here..."
                  : "Enter checkbox question here..."
              }
              value={question.text}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />

            {isEdit && (
              <span
                style={{
                  cursor: "pointer",
                  color: "#4D4D4D",
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
                onClick={() => openModal(index)} // Pass index to openModal
              >
                <MdDelete size={20} />
              </span>
            )}
          </div>

          {question.extraField && question.type === "text" && (
            <input
              type="text"
              className="custom-input1"
              style={{
                border: "none",
                borderBottom: "1px solid #D9D9D9",
                color: "#D9D9D9",
                fontSize: "16px",
                padding: "5px",
                marginTop: "10px",
              }}
              placeholder="Type customized placeholder here..."
            />
          )}

          {question.type === "checkbox" && (
            <div style={{ marginTop: "15px" }}>
              {question.options.map((option, optionIndex) => (
                <div key={optionIndex} style={{ display: "flex", alignItems: "center", marginBottom: "5px", paddingBottom: "5px" }}>
                  <input
                    type="checkbox"
                    style={{
                      width: "18px",
                      height: "18px",
                      marginRight: "10px",
                      border: "1px solid #4D4D4D",
                    }}
                  />
                  <input
                    type="text"
                    style={{
                      border: "none",
                      borderBottom: "none",
                      width: "100%",
                      color: "#4D4D4D",
                    }}
                    placeholder="Write answer here.."
                    value={option}
                    onChange={(e) =>
                      handleOptionChange(index, optionIndex, e.target.value)
                    }
                  />
                </div>
              ))}
              {isEdit && (
                <h4
                  style={{
                    color: "#080700",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0px",
                    marginLeft: "10px",
                  }}
                  onClick={() => handleAddOption(index)}
                >
                  <IoIosAddCircleOutline size={20} style={{ marginRight: "5px", color: "#2A1980" }} /> Add New Item
                </h4>
              )}
            </div>
          )}
        </div>
      ))}

      <FileUploadComponent brandingColours={brandingColours} />
      <div style={{ position: "relative", display: "inline-block", marginBottom: "20px" }}>
        {showQuestionOptions && (
          <div ref={optionsBoxRef} style={{ width: "260px", padding: "15px", backgroundColor: "#f9f9f9", border: "1px solid #ddd", borderRadius: "5px", boxShadow: "0px 4px 8px rgba(0,0,0,0.1)", marginBottom: "10px" }}>
            <h4 style={{ color: "#080700", marginBottom: "10px", marginTop: "0px" }}>Add an Element</h4>
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }} onClick={() => handleAddQuestion("checkbox")}>
                <MdCheckBox size={40} color="#D9D9D9" />
                <span color="#595959">Checkboxes</span>
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }} onClick={() => handleAddQuestion("text")}>
                <MdTextFields size={40} color="#D9D9D9" />
                <span color="#595959">Question</span>
              </div>
            </div>
          </div>
        )}
        {isEdit && (
          <h4 style={{ color: "#080700", cursor: "pointer", display: "flex", alignItems: "center", marginTop: "0px", marginLeft: "10px" }} onClick={handleAddQuestionClick}>
            <IoIosAddCircleOutline size={20} style={{ marginRight: "5px", color: "#2A1980" }} /> Add New Item
          </h4>
        )}
      </div>
      <div className="right-panel" style={{ marginTop: "99px", width: "19%", height: "auto", paddingLeft: "1%" }}>
        <button style={{ height: "30px", fontSize: "15px", background: "#FFFFFF", color: "#2A1980", border: "1px solid #D9D9D9", width: "30%", cursor: "pointer" }} onClick={handleEditSave}>
          {!isEdit ? <MdEdit /> : <FaRegCheckCircle />} {isEdit ? "Save" : "Edit"}
        </button>
        <button style={{ height: "30px", fontSize: "15px", background: "#2A1980", color: "#FFFFFF", marginLeft: "15px" }}>
          <BsSend /> Send to Client
        </button>
      </div>
    </div>
  );
};

export default QuestionnaireTabBusiness;
