import React from "react";
import profilePic from "../../images/logo.webp";
import FileUploadComponent from "../fileUpload/FileUploadComponent.js";
import InvoiceRightPanel from "../panels/InvoiceRightPanel";
import profilePicture from "../../images/John-Wick.jpg.avif";
import "../../styling/InvoiceTab.css";

const InvoiceTab = ({ brandingColours}) => {
  return (
    // <div className="right-panel-content">
    //   <div style={{ marginTop: '0px', padding: '20px'}}>
    //         <div className="right-panel-arrow-container">

    //            <img style={{maxWidth: '15%', height: 'auto', padding: '20px'}} src={profilePicture}/>
    //           <div style={{marginInline: '0px' }}>Billed By</div>

    //           <div style={{  width: '35%', fontSize: '15px', marginRight: '25px', marginTop: '25px', color: "#4D4D4D" }}> Invoice #0022 <br/> Issued : Jun 13, 2022 <br/> Due : Jun 13, 2022 </div>
    //         </div>

    //         <div style={{ color: '#4D4D4D'}}>
    //           <p>
    //           3 Hours On-Site Attendant <br/> Unlimited Prints Online <br/> Downloads Party Props <br/> Custom Photostrips Design
    //           </p>
    //         </div>
    //       </div>
    // </div>
    <div style={{width: '88%'}}>
      <div className="bordered-box">
        <img src={profilePic} alt="Your Image" className="image" />
        <div className="invoiceContent1">
          <div style={{ color: "#000000", fontWeight: "bold" }}>
            {" "}
            Billed By{" "}
          </div>
          <p
            style={{ color: "#4D4D4D", marginTop: "7px", marginBottom: "5px" }}
          >
            Google Inc.{" "}
          </p>
          <p style={{ color: "#4D4D4D", marginTop: "0px" }}>
            {" "}
            tribeportraits@gmail.com
          </p>
        </div>
        <div>
          <p className="rightmost-content">
            Invoice #0022 <br /> Issued : Jun 13, 2022 <br /> Due : Jun 13, 2022
          </p>
        </div>
      </div>

      <div>
        <div className="invoiceContent">
          <div style={{ color: "#000000", fontWeight: "bold" }}>
            {" "}
            Billed To{" "}
          </div>
          <p
            style={{ color: "#4D4D4D", marginTop: "7px", marginBottom: "5px" }}
          >
            John{" "}
          </p>
          <p
            style={{ color: "#4D4D4D", marginTop: "0px", marginBottom: "5px" }}
          >
            {" "}
            Wick
          </p>
          <p style={{ color: "#4D4D4D", marginTop: "0px" }}>
            {" "}
            johnwick1234@gmail.com
          </p>
          <h2 style={{ marginTop: "30px", color: "#000000" }}>Items</h2>

          <div
            className="right-panel-content"
            style={{ marginTop: "0px", padding: "20px" }}
          >
            <div className="right-panel-arrow-container">
              <span>
                <h2
                  style={{
                    color: "#000000",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "425",
                  }}
                >
                  Premier Package
                </h2>
              </span>{" "}
              <h2
                style={{
                  alignSelf: "flex-end",
                  marginRight: "10px",
                  marginBottom: "5px",
                  color: "#000000",
                  fontWeight: "425",
                  marginTop: "auto",
                }}
              >
                {" "}
                $250.00{" "}
              </h2>
            </div>

            <div style={{ color: "#4D4D4D" }}>
              <p style={{ marginBottom: "3px" }}>3 Hours On-Site Attendant</p>
              <p style={{ marginBottom: "3px", marginTop: "3px" }}>
                Unlimited Prints
              </p>
              <p style={{ marginBottom: "3px", marginTop: "3px" }}>
                Online Downloads
              </p>
              <p style={{ marginBottom: "3px", marginTop: "3px" }}>
                Party Props Custom
              </p>
              <p style={{ marginBottom: "3px", marginTop: "3px" }}>
                Photostrips Design
              </p>
            </div>
          </div>

          <div
            style={{
              borderBottom: "1px solid #D9D9D9",
              color: "#4D4D4D",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              padding: "1.3%",
            }}
          >
            <div>Subtotal</div>
            <div style={{ width: "20%" }}></div>
            <div>$250.00</div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #D9D9D9",
              color: "#4D4D4D",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              padding: "1.3%",
            }}
          >
            <div>Total</div>
            <div style={{ width: "20%" }}></div>
            <div>$250.00</div>
          </div>
          <div
            style={{
              color: "#4D4D4D",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              padding: "1.3%",
            }}
          >
            <div>Minimum amount due</div>
            <div style={{ width: "20%" }}></div>
            <div>$150.00</div>
          </div>
        </div>
      </div>
      <div>
        <InvoiceRightPanel brandingColours= {brandingColours}/>
      </div>
    </div>
  );
};

export default InvoiceTab;
