import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ClientLandingPage from "./ClientLandingPage";
import ClientPortal from "./ClientPortal";
import LeftClientPanel from "../panels/LeftClientPanel";
import axios from 'axios';
import ClientHeader from "./ClientHeader";
import "../../styling/App.css";

const ClientContainer = () => {
  const [client, setClient] = useState({});
  const [brandingColours, setBrandingColours] = useState({ primaryColor: '#008000', secondaryColor: '#dddddd' });
  const [selectedTab, setSelectedTab] = useState('landing'); // Flag to control which set of tabs is shown
  const [events, setEvents] = useState([]);
  const [brandLogo, setBrandLogo] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");


  // Fetch client data and branding on component mount
  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get('http://localhost:3001/client-user', { withCredentials: true });
        setClient(response.data.data);
        console.log(client);
      } catch (error) {
        console.log('Error fetching client data: ', error);
      }
    };

    const fetchBusinessBranding = async (businessId) => {
      try {
        const response = await axios.post('http://localhost:3001/client/get-branding', {
          businessId: "65d086889fec7c067fce1292"
        }, { withCredentials: true });

        // Update branding colors
        const { primaryColor, secondaryColor, brandLogo } = response.data;
        setBrandingColours({ primaryColor, secondaryColor });
        setBrandLogo(brandLogo)
      } catch (error) {
        console.log('Error fetching branding data: ', error);
      }
    };

    const fetchEventData = async () => {
      try {
        const response = await axios.get('http://localhost:3001/client/events', { withCredentials: true });

        setEvents(response.data.data);
      } catch (error) {
        console.log('Error fetching branding data: ', error);
      }
    };

    fetchClientData().then(() => {
      if (businessId) {
        fetchBusinessBranding({businessId});
        fetchEventData()
      }
    });
  }, [client.businessId]);

  // Handle tab switch between landing page and portal
  const handleTabSwitch = (tab) => {
    setSelectedTab(tab);
  };

  const handleBacktoAccount = () => {
    setSelectedTab('landing');
  }

  return (
    <div style={{backgroundColor: "#f2f2f2"}}>
        <div>
            <ClientHeader style={{backgroundColor:"#ffffff"}} brandLogo={brandLogo}/>
        </div>
    <div style={{ display: 'flex' }}>
        
      <div className="clientProfileDetails">
        {/* Left Panel stays constant */}
        <LeftClientPanel
          handleBackToAccount={handleBacktoAccount}
          clientData={client}
          brandingColours={brandingColours}
          selectedTab={selectedTab}
        />
      </div>
      <div className="clientLandingTabs" style={{ paddingLeft: '10px' }}>
        {selectedTab === 'landing' ? (
          <ClientLandingPage
            brandingColours={brandingColours}
            handleTabSwitch={handleTabSwitch}
            events={events}
          />
        ) : (
          <ClientPortal
            brandingColours={brandingColours}
          />
        )}
      </div>
    </div>
    </div>
  );
};

export default ClientContainer;
