import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, loginFailure } from "../authSlice";
import "../styling/SignUp.css";
import { useNavigate } from "react-router-dom";

const CheckVerification = ({ handleIsclient }) => {
  const navigate = useNavigate();
  return (
    <div className="verify-email">
        <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
}}>
      <div style={{ paddingTop: "5%" }}>
        <div
          style={{ fontSize: "30px", color: "#1C1154", marginBottom: "20px" }}
        >
          Check Your Email
        </div>
        <div style={{ color: "#545454" }}>
          <p>
            Please check your email to complete the verification of your account{" "}
          </p>
        </div>
        <button className="login-button">Go Back to login page</button>
      </div>
      </div>
    </div>
  );
};

export default CheckVerification;
