import React from "react";
import '../../styling/RevenueInfoTile.css';

const RevenueInfoTile = ({tile}) => {
  let data = tile ;
  let totalEvents = 0;
  let totalPendingPayments = 0;
  let completedEvents = 0;
  let upcomingEvents = 0;

  if (Array.isArray(data) && data.length > 0 && Array.isArray(data[0]) && data[0].length > 0 && typeof data[0][0] === 'object') {
    // Access the values from the nested objects
     totalEvents = data[0][0].totalEvents;
    totalPendingPayments = data[1][0].totalPendingPayments;
    completedEvents = data[2][0].completedEvents;
    upcomingEvents = data[3][0]?.upcomingEvents || 0 ;
  } 
  return (
    <div
      style={{
        width: "73.3%",
        height: "90px",
        background: "linear-gradient(to right, #1C1154, #260F99)",
        marginTop: "29px",
        display: "flex",
      }}
    >
      <div className="column">
        <p> Total Bookings</p>
        <h1>{totalEvents}</h1>
      </div>
      <div className="column">
        <p> Upcoming Bookings </p>
        <h1>{upcomingEvents}</h1>
      </div>
      <div className="column">
        <p> Completed Bookings</p>
        <h1>{completedEvents}</h1>
      </div>
      <div className="column" style={{ borderRight: 'hidden'}}>
        <p>Pending Payments</p>
        <h1>{totalPendingPayments}</h1>
      </div>
    </div>
  );
};

export default RevenueInfoTile;
