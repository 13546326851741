import React from "react";
import profile from "../../images/logo.webp";
import profilePic from "../../images/happy-birthday-background-sign-tropical-beach-hawaii-63372910.jpg.webp";

import FileUploadComponent from "../fileUpload/FileUploadComponent.js";
import PackageRightPanel from "../panels/PackageRightPanel";
import profilePicture from "../../images/John-Wick.jpg.avif";
import "../../styling/InvoiceTab.css";
import { color } from "@mui/system";

const QuestionnaireTab = ({brandingColours}) => {
  const inputStyle = {
    borderBottom: " 1px solid #D9D9D9",
    borderTop: "hidden",
    borderLeft: "hidden",
    borderRight: "hidden",
    width: "100%",
    paddingBottom: "15px",
    color: "##000000",
  };

  const inputStyle1 = {
    borderBottom: " 1px solid #D9D9D9",
    borderTop: "hidden",
    borderLeft: "hidden",
    borderRight: "hidden",
    width: "100%",
    paddingBottom: "15px",
    color: "##000000",
  };
  return (
    <div style={{ width: '85%'}}>
      <img
        src={profilePic}
        alt="cover"
        style={{ width: "100%", height: "224px" }}
      />

      <div>
        <div className="invoiceContent">
          <h2
            style={{
              marginTop: "30px",
              color: "##000000",
              marginBottom: "30px",
            }}
          >
            Event Info
          </h2>
          <h4 style={{ color: "##000000", marginBottom: "15px" }}>
            {" "}
            What is the name of your event?
          </h4>
          <input
            style={inputStyle}
            placeholder={"Type customized placeholder here..."}
          ></input>

          <h4
            style={{
              color: "##000000",
              marginBottom: "15px",
              marginTop: "30px",
            }}
          >
            {" "}
            What type of event is it?{" "}
          </h4>
          <input
            style={inputStyle}
            placeholder={"Type customized placeholder here..."}
          ></input>

          <h4
            style={{
              color: "##000000",
              marginBottom: "15px",
              marginTop: "30px",
            }}
          >
            {" "}
            What size of photostrip would you like?{" "}
          </h4>
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "15px" }}
          >
            <input
              style={{
                width: "18px",
                height: "18px",
                marginRight: "5px",
                border: "1px solid #4D4D4D",
              }}
              type="checkbox"
            />
            <div
              style={{
                marginBottom: "3px",
                fontSize: "15px",
                color: "#4D4D4D",
              }}
            >
              {" "}
              2x6{" "}
            </div>{" "}
          </div>
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "15px" }}
          >
            <input
              style={{ width: "18px", height: "18px", marginRight: "5px" }}
              type="checkbox"
            />{" "}
            <div
              style={{
                marginBottom: "3px",
                fontSize: "15px",
                color: "#4D4D4D",
              }}
            >
              {" "}
              4x6{" "}
            </div>{" "}
          </div>

          <h4
            style={{
              color: "##000000",
              marginBottom: "15px",
              marginTop: "30px",
            }}
          >
            {" "}
            Is there any additional information about your event?{" "}
          </h4>
          <input
            style={inputStyle1}
            placeholder={"Type customized placeholder here..."}
          ></input>

          <h4
            style={{
              color: "##000000",
              marginBottom: "15px",
              marginTop: "30px",
            }}
          >
            {" "}
            Do you have any logos or graphics you want to include?{" "}
          </h4>
          <FileUploadComponent brandingColours={brandingColours} />
        </div>
      </div>
      <div
        className="right-panel"
        style={{ marginTop: "107px", width: "22%", height: "auto" }}
      >
        <div>
          <div
            className="right-panel-content"
            style={{
              color: "##000000",
              marginTop: "2px",
              marginBottom: "15px",
            }}
          >
            01
            <div style={{ marginBottom: "15px", marginTop: "5px" }}>
              Event Info
            </div>
          </div>
          <div
            className="right-panel-content"
            style={{
              color: "##000000",
              marginTop: "2px",
              marginBottom: "15px",
            }}
          >
            02
            <div style={{ marginBottom: "15px", marginTop: "5px" }}>
              Style
            </div>
          </div>
          <div
            className="right-panel-content"
            style={{
              color: "##000000",
              marginTop: "2px",
              marginBottom: "15px",
            }}
          >
            03
            <div style={{ marginBottom: "15px", marginTop: "5px" }}>
              Photostrip Design
            </div>
          </div>

          <div
            className="right-panel-content"
            style={{
              color: "##000000",
              marginTop: "0px",
              marginBottom: "15px",
            }}
          >
            04
            <div style={{ marginBottom: "15px", marginTop: "5px" }}>
              Additional Information
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireTab;
