import React, { createContext, useState } from "react";

export const EventDataContext = createContext();

export const EventDataProvider = ({ children }) => {
  const [EventData, setEventData] = useState({
    eventName: "",
    eventType: "",
    referralSource: "",
    eventDate: "",
    startTime: "",
    endTime: "",
    location: "",
    details: "",
  });

  return (
    <EventDataContext.Provider value={{ EventData, setEventData }}>
      {children}
    </EventDataContext.Provider>
  );
};