import "../styling/App.css";
import React, { useEffect, useState } from "react";
import LeftMenu from "./LeftMenu";
import Content from "./Content";
import Header from "./header";
import BusinessClientView from "./BusinessClientView";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Views = ({ handleMenuItemClick, selectedMenuItem, handleDropdownClick, onEventClick }) => {
  const [selectedClientView, setSelectedClientView] = useState(true);
  const [clientRecord, setClientRecord] = useState(null);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();

  // const handleDropdownClick = (account) => {
    
  // };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  const onClientClick = (record) => {
    setClientRecord(record);
    setSelectedClientView(!selectedClientView);
  };

  const onClickBacktoAccount = () => {
    setSelectedClientView(!selectedClientView);
  };

  return (
    <div className="appContain">
      <Header
        onClickAccountInfo={handleDropdownClick}
        selectedMenuItem={selectedMenuItem}
      />

      <div className="app">
        <LeftMenu
          selectedMenuItem={selectedMenuItem}
          onMenuItemClick={handleMenuItemClick}
        />
        <Content
          selectedMenuItem={selectedMenuItem}
          handleClientClick={onClientClick}
          onEventClick={onEventClick}
        />
      </div>
    </div>
  );
};

export default Views;
