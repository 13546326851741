import React, { useState, useRef, useEffect } from "react";
import "../styling/Account.css";
import profilePicture from "../images/John-Wick.jpg.avif";
import logo from "../images/logo.webp";
import { FaUpload } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { PiLinkBold } from "react-icons/pi";
import { ImDownload3 } from "react-icons/im";
import ProductModal from "./modals/ProductModal";
import ColorPicker from "./ColorPicker";
import { GoSmiley } from "react-icons/go";
import CreateBookingModal from "./modals/CreateBookingModal";
import axios from "axios";

const CompanyInfo = () => {
  const [isPrimaryPickerVisible, setIsPrimaryPickerVisible] = useState(false);
  const [isSecondaryPickerVisible, setIsSecondaryPickerVisible] =
    useState(false);
  const [primaryColor, setPrimaryColor] = useState('#1C1154');
  const [secondaryColor, setSecondaryColor] = useState('#ffffff');
  const [brandLogo, setBrandLogo] = useState(null);
  const [brandingColours, setBrandingColours] = useState({ primaryColor: '#008000', secondaryColor: '#dddddd' });

  const [tempColor, setTempColor] = useState("green");

  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchBusinessBranding();
  }, []);

  const handleButtonClick = () => {
    // Programmatically click the hidden file input
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    // Get the selected file
    const file = event.target.files[0];
    if (file) {
      console.log('Selected file:', file);
    }
  };

  const togglePrimaryPicker = () => {
    setIsPrimaryPickerVisible(!isPrimaryPickerVisible);
    setTempColor(primaryColor);
  };

  const toggleSecondaryPicker = () => {
    setIsSecondaryPickerVisible(!isSecondaryPickerVisible);
    setTempColor(secondaryColor);
  };

  const handleCancel = () => {
    setIsPrimaryPickerVisible(false);
    setIsSecondaryPickerVisible(false);
  };

  const handleChoose = async (selectedColor, type) => {
    if (type === "primary") {
      setPrimaryColor(selectedColor);
      setIsPrimaryPickerVisible(false);
      // Call API to set the primary color
      await setBranding({ primaryColor: selectedColor });
    } else if (type === "secondary") {
      setSecondaryColor(selectedColor);
      setIsSecondaryPickerVisible(false);
      // Call API to set the secondary color
      await setBranding({ secondaryColor: selectedColor });
    }
  };

  const setBranding = async (data) => {
    try {
      await axios.post("http://localhost:3001/set-branding", data, {
        withCredentials: true,
      });
      console.log("Branding data updated:", data);
    } catch (error) {
      console.log("Error setting branding data...", error);
    }
  };


  const fetchBusinessBranding = async () => {
    try {
      const response = await axios.get('http://localhost:3001/get-branding', { withCredentials: true });

      const { primaryColor, secondaryColor, brandLogo } = response.data;
      setBrandingColours({ primaryColor, secondaryColor });
      setPrimaryColor(primaryColor);
      setSecondaryColor(secondaryColor);
      setBrandLogo(brandLogo);
    } catch (error) {
      console.log('Error fetching branding data: ', error);
    }
  };

  const smiley = (
    <div style={{ marginRight: "8px", marginTop: "4px" }}>
      <GoSmiley />
    </div>
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ width: "80%" }}>
      <div className="accountInfo">
        <div>
          {" "}
          <h2>Company Profile</h2>{" "}
        </div>
        <div
          className="accountButtons"
          style={{ width: "35%", marginRight: "0w.5%" }}
        >
          <button
            style={{
              height: "40px",
              fontSize: "14px",
              background: "#FFFFFF",
              color: "#1C1154",
            }}
          >
            {" "}
            Account
          </button>
          <button style={{ height: "40px", fontSize: "14px" }}> Company</button>
          <button
            style={{
              height: "40px",
              fontSize: "14px",
              background: "#FFFFFF",
              color: "#1C1154",
            }}
          >
            {" "}
            Book-A-Booth
          </button>
        </div>
      </div>

      <div
        style={{
          height: "auto",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Company Branding
        </h2>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "50px",
            marginTop: "30px",
          }}
        >
          <div style={{ display: "flex" }}>
            <img
              src={profilePicture}
              alt="Profile"
              style={{ width: "70px", height: "70px", borderRadius: "50%" }}
            />
            <div
              className="accountButtons"
              style={{
                gap: "17px",
                marginLeft: "4%",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div>
                <div style={{}}>
                  {isPrimaryPickerVisible && (
                    <div
                      style={{
                        position: "absolute",
                        top: "100px",
                        zIndex: 999,
                        backgroundColor: "white",
                        padding: "20px",
                        borderRadius: "8px",
                      }}
                    >
                      <ColorPicker
                        initialColor={tempColor}
                        onCancel={handleCancel}
                        onChoose={(color) => handleChoose(color, "primary")}
                      />
                    </div>
                  )}
                </div>
                <button
                  style={{
                    width: "60%",
                    height: "45px",
                    fontSize: "18px",
                    background: "#FFFFFF",
                    border: "1px solid #D9D9D9",
                    color: "#1C1154",
                  }}
                >
                  <FaUpload /> Profile Image
                </button>

                <div style={{ marginTop: "15px" }}>
                  Upload png, jpg, or svg images to 256x256 px.
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                backgroundColor: primaryColor,
              }}
            />

            <div
              className="accountButtons"
              style={{
                gap: "17px",
                marginLeft: "4%",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div>
                <button
                  style={{
                    width: "146%",
                    height: "45px",
                    fontSize: "18px",
                    background: "#FFFFFF",
                    border: "1px solid #D9D9D9",
                    color: "#1C1154",
                    marginBottom: "30px",
                  }}
                  onClick={togglePrimaryPicker}
                >
                  Button Color
                </button>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <img
              src='https://picsum.photos/1200/300?random'
              alt="Profile"
              style={{ width: "70px", height: "70px" }}
            />
            <div
              className="accountButtons"
              style={{
                gap: "10px",
                marginLeft: "4%",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div style={{ marginBottom: '100px'}}>
                <button
                  style={{
                    width: "60%",
                    height: "45px",
                    fontSize: "18px",
                    background: "#FFFFFF",
                    border: "1px solid #D9D9D9",
                    color: "#1C1154",
                  }}
                  onClick={handleButtonClick}
                >
                  <FaUpload /> Upload Logo
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <div style={{ marginTop: "15px" }}>
                  Upload png, jpg, or svg images to 256x256 px.
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                backgroundColor: secondaryColor,
              }}
            />
            <div
              className="accountButtons"
              style={{
                gap: "17px",
                marginLeft: "4%",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div>
                <button
                  style={{
                    width: "60%",
                    height: "45px",
                    fontSize: "18px",
                    background: "#FFFFFF",
                    border: "1px solid #D9D9D9",
                    color: "#1C1154",
                    marginBottom: "30px",
                    marginTop: "15px",
                  }}
                  onClick={toggleSecondaryPicker}
                >
                  Button Text Color
                </button>
                <button
                  style={{
                    width: "60%",
                    height: "45px",
                    fontSize: "18px",
                    background: primaryColor,
                    border: "1px solid #D9D9D9",
                    color: secondaryColor,
                    marginBottom: "30px",
                    marginTop: "50px",
                  }}
                >
                  Button Text Color
                </button>
              </div>
            </div>
            {isSecondaryPickerVisible && (
              <div
                style={{
                  position: "absolute",
                  top: "200px",
                  zIndex: 999,
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                <ColorPicker
                  initialColor={tempColor}
                  onCancel={handleCancel}
                  onChoose={(color) => handleChoose(color, "secondary")}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          height: "400px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Company Info
        </h2>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Business Name
            </div>
            <input
              placeholder="John"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Website
            </div>
            <input
              placeholder="Doe"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Email
            </div>
            <input
              placeholder="JohnDoe@gamail.com"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Phone Number
            </div>
            <input
              placeholder="(000) 000-0000"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            Street
          </div>
          <input
            placeholder="(000) 000-0000"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            City, State
          </div>
          <input
            placeholder="(000) 000-0000"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            Zip Code
          </div>
          <input
            placeholder="(000) 000-0000"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
      </div>
      <div
        style={{
          height: "346px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Team Members
        </h2>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              First Name
            </div>
            <input
              placeholder="John"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Last Name
            </div>
            <input
              placeholder="Doe"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Email
            </div>
            <input
              placeholder="John"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Phone Number
            </div>
            <input
              placeholder="Doe"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "35px" }}>
          <img
            src={profilePicture}
            alt="Profile"
            style={{ width: "70px", height: "70px", borderRadius: "50%" }}
          />
          <div
            className="accountButtons"
            style={{
              gap: "17px",
              marginLeft: "4%",
              width: "100%",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "20%",
                height: "40px",
                fontSize: "14px",
                background: "#FFFFFF",
                border: "1px solid #D9D9D9",
                color: "#1C1154",
              }}
            >
              {" "}
              <FaUpload /> Upload Photo
            </button>
            <button
              style={{
                width: "27%",
                height: "40px",
                fontSize: "14px",
                background: "#F2F2F2",
                border: "1px solid #D9D9D9",
                color: "#1C1154",
              }}
            >
              {" "}
              <FaTrash /> Delete Team Member
            </button>
          </div>
        </div>
        <button
          className="addTeamMemberButton"
          style={{ marginTop: "20px", width: "25%" }}
        >
          {" "}
          {smiley} Add Team Member{" "}
        </button>
      </div>
      <div
        style={{
          height: "360px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Share Links
        </h2>
        <h4 style={{ color: "#1C1154" }}>Client Portal</h4>
        <h5 style={{ color: "#4D4D4D", fontSize: "15px" }}>
          Paste this link to your website for your clients to access their
          portals.
        </h5>
        <div style={{ display: "flex", justifyItems: "baseline" }}>
          <PiLinkBold style={{ fontSize: "25px" }} />{" "}
          <div
            style={{ paddingTop: "3px", paddingLeft: "4px", color: "#1C1154" }}
          >
            www.boothbloom.com/account
          </div>
        </div>

        <h4 style={{ color: "#1C1154" }}>Book-A-Booth</h4>
        <h5 style={{ color: "#4D4D4D", fontSize: "15px" }}>
          Share links or paste on your website or directly to you contacts.
        </h5>
        <div style={{ display: "flex", justifyItems: "baseline" }}>
          <PiLinkBold style={{ fontSize: "25px" }} />{" "}
          <div
            style={{ paddingTop: "3px", paddingLeft: "4px", color: "#1C1154" }}
          >
            www.boothbloom.com/book-a-booth
          </div>
        </div>
      </div>
      <div
        style={{
          height: "260px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "50px" }}
        >
          Membership
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4 style={{ color: "#1C1154" }}>Unlimited Monthly</h4>
          <button
            onClick={openModal}
            style={{
              height: "40px",
              fontSize: "14px",
              background: "#FFFFFF",
              color: "#1C1154",
              border: "1px solid #D9D9D9",
              width: "15%",
              cursor: "pointer",
            }}
          >
            {" "}
            Change Plan{" "}
          </button>
        </div>
        <ProductModal isModalOpen={isModalOpen} closeModal={closeModal} />
        <div
          style={{
            color: "#4D4D4D",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h5>
            All features, no limits. Unlimited support. Unlimited team members.{" "}
            <br /> 3% credit card of 1.5% ACH fee.
          </h5>
          $25/month
        </div>
        <h5 style={{ color: "#4D4D4D", fontSize: "15px" }}>
          Next payment: $25 on Dec 12, 2022
        </h5>
      </div>
      <div
        style={{
          height: "230px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "35px" }}
        >
          Billing
        </h2>
        <div
          style={{
            color: "#4D4D4D",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4>Payment Method</h4>
          Credit Card
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            Card Number
          </div>
          <input
            placeholder="*************"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
      </div>
      <div
        style={{
          height: "260px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2 style={{ marginTop: "30px", color: "#1C1154", fontWeight: "450" }}>
          Invoice History
        </h2>
        <div>
          <div
            style={{
              color: "#4D4D4D",
              display: "flex",
              justifyContent: "space-between",
              padding: "2.3%",
              borderBottom: "1px solid #D9D9D9",
              paddingRight: "7%",
            }}
          >
            <div>Date</div>
            <div style={{ marginLeft: "40px" }}>Plan</div>
            <div>Amount</div>
            <div>Download Invoice</div>
          </div>
          <div
            style={{
              color: "#4D4D4D",
              display: "flex",
              justifyContent: "space-between",
              padding: "1.3%",
              paddingRight: "20%",
              borderBottom: "1px solid #D9D9D9",
            }}
          >
            <div>01/01/2024</div>
            <div>Starter</div>
            <div>$25.00</div>
            <div>
              <ImDownload3 />{" "}
            </div>
          </div>
          <div
            style={{
              color: "#4D4D4D",
              display: "flex",
              justifyContent: "space-between",
              padding: "1.3%",
              borderBottom: "1px solid #D9D9D9",
              paddingRight: "20%",
            }}
          >
            <div>01/01/2024</div>
            <div>Starter</div>
            <div>$25.00</div>
            <div>
              <ImDownload3 />{" "}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: "300px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Bank Info
        </h2>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            Business Type
          </div>
          <input
            placeholder="*************"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            Bank Account
          </div>
          <input
            placeholder="*************"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            Business Owner
          </div>
          <input
            placeholder="John Doe"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
        <button
          style={{
            marginTop: "35px",
            height: "40px",
            width: "30%",
            background: "#FFFFFF",
            border: "1px solid #D9D9D9 ",
            color: "#1C1154",
            fontSize: "17px",
            fontWeight: "450",
          }}
        >
          Change Bank Info
        </button>
      </div>
    </div>
  );
};

export default CompanyInfo;
