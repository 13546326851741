import React from "react";
import  Modal  from "react-modal";
import '../../styling/Modal.css'

const DeleteQuestionModal = ( {isModalOpen, closeModal, deleteQuestion } ) => {
    
    return (
        <div>
            <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            ariaHideApp={false}
            className="deleteQuestionModal"
            
          >
            <button className="close-button" onClick={closeModal}>
            &#10005;
          </button>
             <div>
              <h2 style={{ color: '#000000', fontWeight: '400', paddingLeft: '8%', paddingTop: '3%', paddingBottom: '0%', paddingRight: '8%' }}>Before You Delete</h2>
              <p style={{ color: '#625C73', fontSize: '15px', paddingLeft: '8%', paddingRight: '20%'}}>If client has already provided an answer, both the question and answer will be deleted. Do you still want to delete?</p>
           <div style={{ paddingLeft: '8%', width: '80%', marginBottom: '40px'}}>
            <button type="button" onClick={closeModal} style={{ cursor: 'pointer', width: '42%', height: '50px', background: '#FFFFFF', border: '1px solid #43425D', color: '#2A1980', fontSize: '15px'  }}>
            Don’t Delete
            </button>
            <button type="button" onClick={deleteQuestion} style={{ cursor: 'pointer', width: '42%', height: '50px', marginLeft: '2%', background: '#211074', border: '1px solid #43425D', color: '#FFFFFF', fontSize: '15px'}}>
            Delete
            </button>
            </div>
            </div>
            
          </Modal>
        </div>
    );
}

export default DeleteQuestionModal;