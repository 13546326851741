import React from "react";
import "../../styling/Dashboard.css";

import { ImDownload3 } from "react-icons/im";

const InvoiceRightPanel = ({ brandingColours }) => {
  return (
    <div
      className="right-panel"
      style={{ marginTop: "105px", width: "20%", height: "auto" }}
    >
      <div>
        <div className="right-panel-content">
          <h4
            style={{
              color: "#000000",
              marginTop: "0px",
              marginBottom: "5px",
              fontWeight: "500",
            }}
          >
            Balance Due:
          </h4>
          <div style={{ display: "flex" }}>
            <h1
              style={{
                color: "#000000",
                marginBottom: "20px",
                marginTop: "5px",
                fontWeight: "500",
              }}
            >
              {" "}
              $0.00
            </h1>
            <div
              style={{
                backgroundColor: "#E1FAE3",
                color: "#21A62C",
                padding: "10px",
                position: "absolute",
                right: "9%",
              }}
            >
              Paid
            </div>
          </div>
        </div>
        <div
          className="right-panel-content"
          style={{
            marginTop: "0px",
            borderTop: "hidden",
            borderBottom: "hidden",
          }}
        >
          <div className="right-panel-arrow-container">
            <span>
              <p
                style={{
                  color: "#4D4D4D",
                  marginTop: "0px",
                  marginBottom: "5px",
                }}
              >
                Original Invoice Total
              </p>
            </span>{" "}
            <div
              style={{
                alignSelf: "flex-end",
                fontSize: "15px",
                marginRight: "30px",
                marginBottom: "5px",
                color: "#4D4D4D",
              }}
            >
              {" "}
              $250.00{" "}
            </div>
          </div>
        </div>

        <div
          className="right-panel-content"
          style={{ marginTop: "0px", borderTop: "hidden", paddingTop: "0px" }}
        >
          <div className="right-panel-arrow-container">
            <span>
              <p
                style={{
                  color: "#4D4D4D",
                  marginTop: "0px",
                  marginBottom: "5px",
                }}
              >
                Total amount recieved
              </p>
            </span>{" "}
            <div
              style={{
                alignSelf: "flex-end",
                fontSize: "15px",
                marginRight: "30px",
                marginBottom: "5px",
                color: "#4D4D4D",
              }}
            >
              {" "}
              $250.00{" "}
            </div>
          </div>
        </div>

        <div
          className="right-panel-content"
          style={{ marginTop: "0px", borderTop: "hidden" }}
        >
          <h2 style={{ color: "#000000", fontWeight: "400", marginTop: "0px" }}>
            {" "}
            Invoice Activity
          </h2>
          <h4
            style={{
              color: "#000000",
              fontWeight: "400",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          >
            {" "}
            June 17, 2026
          </h4>
          <p style={{ fontSize: "12px", marginTop: "15px", color: "#4D4D4D" }}>
            {" "}
            11:44 PM{" "}
          </p>
          <p style={{ fontSize: "12px", marginTop: "0px", color: "#4D4D4D" }}>
            {" "}
            Heartbreakent06@yahoo.com made a $175.00 payment.
          </p>
        </div>
      </div>
      <div style={{ marginTop: "0px", width: "65.4%", paddingLeft: "5%" }}>
        <div style={{ marginTop: "15px", marginBottom: "30px" }}>
          <button
            style={{
              marginTop: "0px",
              width: "100%",
              height: "35px",
              background: brandingColours?.primaryColor,
              color: brandingColours?.secondaryColor,
              fontSize: "15px",
              border: "hidden",
              cursor: 'pointer'
            }}
            className="events-button-hover-underline"
          >
            <span>
              {" "}
              <ImDownload3 />{" "}
            </span>{" "}
            Download Invoice
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceRightPanel;
