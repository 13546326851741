import React from "react";
import profile from "../../images/logo.webp";
import profilePic from "../../images/happy-birthday-background-sign-tropical-beach-hawaii-63372910.jpg.webp";

import FileUploadComponent from "../fileUpload/FileUploadComponent.js";
import PackageRightPanel from "../panels/PackageRightPanel";
import profilePicture from "../../images/John-Wick.jpg.avif";
import "../../styling/InvoiceTab.css";

const PackagesTab = () => {


  return (
    <div style={{width: '85%'}}>
      <img
        src={profilePic}
        alt="cover"
        style={{ width: "100%", height: "224px" }}
      />

      <div>
        <div className="invoiceContent">
          <div
            className="right-panel-content"
            style={{ marginTop: "0px", padding: "20px" }}
          >
            <div className="right-panel-arrow-container">
              <span>
                <h2
                  style={{
                    color: "##000000",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "425",
                  }}
                >
                  Premier Package
                </h2>
              </span>{" "}
              <h4
                style={{
                  alignSelf: "flex-end",
                  marginRight: "10px",
                  marginBottom: "5px",
                  color: "##000000",
                  fontWeight: "425",
                  marginTop: "auto",
                }}
              >
                {" "}
                $250.00{" "} <input style={{ paddingBottom: '3px'}} type="checkbox" />
              </h4>
            </div>

            <div style={{ color: "#4D4D4D" }}>
              <p style={{ marginBottom: "3px" }}>3 Hours On-Site Attendant</p>
              <p style={{ marginBottom: "3px", marginTop: "3px" }}>
                Unlimited Prints
              </p>
              <p style={{ marginBottom: "3px", marginTop: "3px" }}>
                Online Downloads
              </p>
              <p style={{ marginBottom: "3px", marginTop: "3px" }}>
                Party Props Custom
              </p>
              <p style={{ marginBottom: "3px", marginTop: "3px" }}>
                Photostrips Design
              </p>
            </div>
          </div>
          <h2 style={{ marginTop: "30px", color: "##000000" }}>Add Ons</h2>
          <div className="bordered-box">
        <img src={profilePic} alt="Your Image" className="image" />
        <div className="content">
          <div style={{ color: "##000000" }}>
            {" "}
            Custom Party Props{" "}
          </div>
          <p
            style={{ color: "#4D4D4D", marginTop: "7px", marginBottom: "5px" }}
          >
            Let us know what theme and colors {" "}
          </p>
          <p style={{ color: "#4D4D4D", marginTop: "0px" }}>
            {" "}
            you want and we’ll make it happen!
          </p>
        </div>
        <div>
          <p className="rightmost-content" style={{ marginTop: '-20px', color: '##000000' }}>
            $150.00 <input style={{ paddingBottom: '3px'}} type="checkbox" />
          </p>
        </div>
      </div>

          <div
            style={{
              borderBottom: "1px solid #D9D9D9",
              borderTop: "1px solid #D9D9D9",
              color: "#4D4D4D",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              padding: "1.3%",
              marginTop: '30px',
              paddingTop: '50px'
            }}
          >
            <div>Subtotal</div>
            <div style={{ width: "20%" }}></div>
            <div>$250.00</div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #D9D9D9",
              color: "#4D4D4D",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              padding: "1.3%",
            }}
          >
            <div>Total</div>
            <div style={{ width: "20%" }}></div>
            <div>$250.00</div>
          </div>
          <div
            style={{
              color: "#4D4D4D",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              padding: "1.3%",
            }}
          >
            <div>Minimum amount due</div>
            <div style={{ width: "20%" }}></div>
            <div>$150.00</div>
          </div>

        <h2 style={{ marginTop: "30px", color: "##000000" }}>Payment Schedule</h2>
        <div>
        <div
            style={{
              color: "#4D4D4D",
              display: "flex",
              justifyContent: 'space-between',
              padding: "1.3%",
              borderBottom: "1px solid #D9D9D9",
              paddingRight: '20%'
            }}
          >
            <div>Amount</div>
            <div>When</div>
            <div>Invoice#</div>
            <div>Status</div>
          </div>
          <div
            style={{
              color: "#4D4D4D",
              display: "flex",
              justifyContent: 'space-between',
              padding: "1.3%",
              borderBottom: "1px solid #D9D9D9",
              paddingRight: '20%'
            }}
          >
            <div>50%</div>
            <div style={{ marginLeft: '15px' }}>Initial</div>
            <div>#132321</div>
            <div>Paid</div>
          </div><div
            style={{
              color: "#4D4D4D",
              display: "flex",
              justifyContent: 'space-between',
              padding: "1.3%",
              borderBottom: "1px solid #D9D9D9",
              paddingRight: '20%'
            }}
          >
            <div>50%</div>
            <div style={{ marginLeft: '15px' }}>final</div>
            <div>#132321</div>
            <div>Paid</div>
          </div>
        </div>
        </div>
      </div>
      <div>
        <PackageRightPanel/>
      </div>
    </div>
  );
};

export default PackagesTab;
