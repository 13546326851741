import React, { useState } from "react";
import "../../styling/GalleryTab.css"; // Import your CSS file for styling

import { ImDownload3 } from "react-icons/im";

const itemsPerPage = 12; // Number of images per page

const GalleryTab = ({ brandingColours }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const images = Array.from(
    { length: 20 },
    (_, index) => `https://picsum.photos/400/300?random=${index + 1}`
  );
  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the images for the current page
  const currentImages = images.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(images.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const downloadAllImages = () => {
    // Create a zip file or any other logic to download all images
    console.log("Downloading all images...");
    // Add logic to create a zip file or other download mechanism
  };

  return (
    <div className="gallery-container">
      <div className="image-grid">
        {currentImages.map((image, index) => (
          <div key={index} className="image-item">
            <img src={image} alt={`Image ${index}`} className="img-fluid" />
          </div>
        ))}
      </div>

      <div className="pagination">
        <button
          style={{
            marginTop: "5px",
            width: "20%",
            height: "35px",
            background: brandingColours?.primaryColor,
            color: brandingColours?.secondaryColor,
            fontSize: "12px",
            border: "hidden",
          }}
        >
          <span>
            {" "}
            <ImDownload3 />{" "}
          </span>{" "}
          Download All
        </button>
        {Array.from({ length: totalPages }).map((_, index) => (
          <div
            key={index}
            className='page-item'
            style={{
              backgroundColor: index + 1 === currentPage ? brandingColours?.primaryColor : 'transparent',
              color: index + 1 === currentPage ? brandingColours?.secondaryColor : brandingColours?.primaryColor,
              borderColor: brandingColours?.primaryColor,
              cursor: 'pointer',
              padding: '8px 16px',
              borderRadius: '4px',
              border: '1px solid',
              margin: '4px',
            }}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GalleryTab;
