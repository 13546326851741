import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';


Chart.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const AnalyticsCustomerGraph = () => {
  const months = ['Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];

  const data = {
    labels: months,
    datasets: [
      {
        label: 'Corporate',
        data: [165, 59, 80, 81, 56, 55, 40, 70, 66, 75, 68, 60],
        backgroundColor: '#F99600',
        barThickness: 20,
      },
      {
        label: 'Weddings',
        data: [28, 48, 40, 19, 86, 27, 90, 60, 56, 50, 55, 40],
        backgroundColor: '#21A62C',
        barThickness: 20,
      },
      {
        label: 'Birthdays',
        data: [7, 3, 10, 20, 58, 18, 30, 20, 18, 20, 15, 10],
        backgroundColor: '#FFC700',
        barThickness: 20,
      },
      {
        label: 'Other',
        data: [7, 3, 10, 20, 58, 18, 30, 20, 18, 20, 15, 10],
        backgroundColor: '#D9D9D9',
        barThickness: 20,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        align: 'start',
        labels: {
          usePointStyle: true,
          boxWidth: 18,
          padding: 40
        },
      },
      title: {
        display: true,
        align: "start",
        text: "Customer Growth",
        padding: 0,
        color: "#1C1154",
        font: {
          size: 24,
          family: "Poppins",
        },
      }
    },

    layout: {
        padding: {
          top: 20, // Space between the title and the chart
        },
    },
    
    scales: {
      x: {
        stacked: true,
        beginAtZero: true,
        grid: {
            display: false, // Remove vertical grid lines
          },
      },
      xTopPadding: {
        // Fake x-axis for padding
        position: 'top',
        labels: [''],
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          ticksWidth: 0,
          ticksLength: 0, // Increase ticksLength to increase the "padding"
        },
        border: {
            color: '#ffffff',
        }
      },
      y: {
        beginAtZero: true,
        stacked: true,
        border: {
            color: '#ffffff'
        }
      },
    },
  };

  return (
    
    <div style={ { width: '71%', height: '100%', backgroundColor: '#FFFFFF', paddingLeft: '1.2%', paddingTop: '1%', paddingRight: '1%' } }>
    <Bar data={data} options={options} />
  </div>
  );
};

export default AnalyticsCustomerGraph;
